/* eslint-disable react-hooks/rules-of-hooks */
import {
    Field,
    FieldTypes,
    ListingItem,
    Step,
    PopupProps,
    ExpandableFieldMeta,
    Colors,
    ClickOffer,
    SecondServiceColors,
    Image,
    Category,
} from "@lib/shared/types";
import React, {
    CSSProperties,
    ReactElement,
    useCallback,
    useMemo,
    useState,
} from "react";
import dynamic from "next/dynamic";
import styles from "./styles.module.scss";
import Input from "./fields/input";
import StreetAddressField from "./fields/streetAddress";
import TextArea from "./fields/textarea";
import Select from "./fields/select";
import Radio from "./fields/radio";
import CalculatedYears from "./fields/calculatedYears";
import ProgressBar from "./progressBar";
import Checkbox from "./fields/checkbox";
import Loader from "@components/shared/loader";
import ArrowRightIcon from "@components/svg/arrowRightIcon";
import DatePicker from "./fields/datePicker";
import Modal from "../modal";
import CloseIcon from "@components/svg/closeIcon";
import OtpForm from "./OtpForm";
import Tooltip from "rc-tooltip";
import TooltipIcon from "@components/svg/tooltipIcon";
import flatten from "lodash/flatten";
import { useDomainContext } from "@hooks/useDomainContext";
import { FieldData, ITextBlock, useForm, UseFormHook } from "@hooks/useForm";
import { useFormContext } from "@hooks/useFormContext";
import { useEffect } from "react";
import { useRouter } from "next/router";
import {
    handlePreventPageClose,
    doesOfferHaveALink,
    replacePlaceholders,
    getSecondServiceCategoryIcon,
    extractContentFromHtml,
} from "@lib/sharedUtils";
import { sendTrackingTime as sendVisitMeta } from "src/api/trackingTime";

import TCPA from "./TCPA";
import CompactOffer from "@components/shared/compactOffer";
import { canShowFieldAfterDependency } from "@lib/shared/form";
import OfferBlock from "@components/shared/offer";
import { gettingQueriesAsString } from "@lib/shared/routerQueriesToString";
import WarningAlertIcon from "@components/svg/warningAlertIcon";
import {
    affiliateOfferZones,
    SHARED_FIELDS_CODENAME,
    COMMON_FIELDS_CODENAME,
} from "@lib/shared/config";
import ZipCodeExpandable from "./fields/zipCodeExpandable";
import OffersInForm from "./offersInForm";
import { DomainForm, getClicksSearchList, getListingBySlugV3 } from "src/api";
import InsuranceOffer from "../insuranceOffer";
import { RegulationsContent } from "../regulationsContent/RegulationsContent";
import SecondServiceForms from "./secondService";
import S3Icon from "@components/shared/s3Icon";
import MultipleSelect from "./fields/multipleSelect";
import Range from "./fields/range";
import SmsTCPA from "./TCPA/smsTCPA";
import { generateGlobalVariables } from "@page-builder/utils";
import MissingFieldsHeadline from "./piiCompletion/MissingFieldsHeadline";
import OtherFieldsHeadline from "./piiCompletion/OtherFieldsHeadline";
import ThankYou from "../thankyou";
import FieldWrapper from "./fieldWrapper";
import useWindowSize from "@hooks/useWindowSize";
import preDefinedAnswerSummaryConfigs from "./answerSummary/preDefinedConfigs";
import { Variable } from "@page-builder/ts/generated";
import { cloneDeep } from "lodash";
import FormOfferModal from "../formOfferModal/FormOfferModal";
// import axios from "axios";
const PopupCallUs = dynamic(() => import("@components/shared/popupCallus"));
const AnswerSummary = dynamic(() => import("./answerSummary"));

let modalWidth = 900;
export interface Classes {
    formClassName?: string;
    stepClassName?: string;
    stepTitleClassName?: string;
    stepDescriptionClassName?: string;
    formFieldClassName?: string;
    formButtonClassName?: string;
    formStepInnerClassName?: string;
    fieldsClassName?: {
        all?: string;
        radio?: string;
        textarea?: string;
        input?: string;
        date?: string;
        select?: string;
        multiSelect?: string;
        checkbox?: string;
        zipcodeExpandable?: string;
        streetAddress?: string;
        range?: string;
    };
    fieldsWrapperClassName?: string;
    fieldNoteClassName?: string;
    tcpaClassName?: string;
    labelAsTitleClassName?: string;
    roundedLabelShift?: string;
    noteIndent?: string;
    formFieldLabelClassName?: string;
    formProgressbarClassName?: string;
    sellingPointClassName?: string;
    formSubmitButtonClassName?: string;
    formFieldDescriptionClassName?: string;
    fieldErrorMessageClassName?: string;
    stepTagClassName?: string;
    firstStepClass?: string;
    innerThankYouClassName?: string;
}
export interface StepsConfig {
    stepOrder?: number;
    hideTitle?: boolean;
    hideDescription?: boolean;
    forceStepTitle?: boolean;
    title?: string;
    description?: string;
    hideProgress?: boolean;
}
export type AnswerSummaryConfigs = AnswerSummaryConfig[];
export interface AnswerSummaryConfig {
    // step index to show the summary
    stepIndex: number;
    // fields to show in the summary
    fields: {
        codeName: string;
        gridArea: string;
        placeSelf?: Variable;
        className?: string;
        appendText?: string;
    }[];
    // styling
    gridTemplateAreas: Variable;
    gridTemplateColumns: Variable;
    gridTemplateRows: Variable;
    gap?: Variable;
    // if true then show summary until end of form
    extend?: boolean;
}

export interface FormProps {
    classes?: Classes;
    secondServiceClasses?: Classes;
    colors: Colors;
    secondServiceColors?: SecondServiceColors;
    fieldBorderRadius?: number;
    scrollToTop?: boolean;
    showProgress?: boolean;
    backButton?: {
        class?: string;
        label?: string;
        firstStepAction?: () => void;
    };
    sellingPoint?: string;
    sellingPointsSteps?: "ALL_STEPS" | "FIRST_STEP";
    customMainTitle?: string;
    preventWindowClose?: boolean;
    progressShift?: number;
    goToThankYouPage?: boolean;
    stepsConfig?: StepsConfig[] | undefined;
    dynamicThankYouFields?: string[];
    // PopupOffer?: ReactElement;
    popupStyle?: PopupProps;
    modalSize?: number;
    currentCategorySlug?: string;
    hideOffers?: boolean;
    ignoreOptionIcons?: boolean;
    customThankYouStep?: ReactElement;
    form?: DomainForm;
    isSecondServiceForm?: boolean;
    isPiiCompletionForm?: boolean;
    updateSecondServices?: (slug: string) => void;

    tags?: { [x: string]: { slug: string; active: boolean } };
    activeSecondServices?: string[];
    styles?: { [x: string]: string };
    secondServiceLoading?: boolean;
    showStepNumberOnly?: boolean;
    formTemplateProp?: "v1" | "v2";
    resetLeadId?: boolean;
    searchableMultiSelect?: boolean;
    secondServiceSearchableMultiSelect?: boolean;
    showInModal?: boolean;
    onModalClickClose?: () => void;
    answerSummaryConfigs?: AnswerSummaryConfigs;
    ignoreCategoryInThankYouPagePath?: boolean;
    isFormInsideOffersModal?: boolean;
    goToListPage?: boolean;
}
export interface StreetAddress {
    streetNumber?: string;
    route?: string;
    zipCode?: string;
    country?: string;
    city?: string;
    state?: string;
}

let popupShowCount = 0;

export interface MutationValue {
    tagName: string;
    outerHTML: string;
    type: string;
    domain: string;
}
// const loggly = axios.create({
//     headers: {
//         "Content-Type": "application/json",
//         accept: "application/json",
//     },
// });
// loggly.interceptors.request.use(
//     (config) => {
//         // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
//         if (config.headers.referer) {
//             // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
//             delete config.headers.referer;
//         }

//         // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
//         if (config.headers?.Referer) {
//             // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
//             delete config.headers.Referer;
//         }

//         return config;
//     },
//     (error) => Promise.reject(error),
// );
export default function Form(props: FormProps): ReactElement {
    const {
        form,
        domain,
        category,
        formTemplate,
        formOffer,
        visitDetails,
        exitModalOffer,
        locale,
        piiCompletionData,
    } = useDomainContext();

    const {
        setSetPopupFormSubmittedFunction,
        skipToThankYou,
        setSecondServiceSubmitted,
        setGoToThankYouPage,
    } = useFormContext();

    const router = useRouter();
    const [offersPopupActive, setOffersPopupActive] = useState(false);
    const [popupOffers, setPopupOffers] = useState<ListingItem[]>([]);

    const [clicksOffers, setClicksOffers] = useState<
        ClickOffer[] | null | undefined
    >([]);
    const [listRequestId, setListRequestId] = useState("");

    // const logDomChanges = (domChanges: MutationValue[]) => {
    //     try {
    //         void loggly.post(
    //             "https://logs-01.loggly.com/inputs/2260ff87-79e4-4c8b-a49a-b4ba85bc3d55/tag/http/",
    //             {
    //                 version: "v35",
    //                 visitId: visitDetails.visitId,
    //                 visitorId: visitDetails.visitorId,
    //                 domainName: domain.name,
    //                 changes: domChanges,
    //                 url: window.location.href,
    //                 userAgent: window.navigator.userAgent,
    //                 environment: process.env.NODE_ENV,
    //             },
    //         );

    //         // eslint-disable-next-line no-empty
    //     } catch (e) {}
    // };
    // const initTracing = (): void => {
    //     const targetNode: HTMLBodyElement | null =
    //         document.querySelector("body");
    //     const headTargetNode: HTMLHeadElement | null =
    //         document.querySelector("head");

    //     const config = { attributes: true, childList: true, subtree: true };

    //     const tracingIgnoredIds: string[] = [
    //         "inspector",
    //         "click-script",
    //         "trustedFormsScript",
    //         "LeadIdScript_campaign",
    //         "click-script-id",
    //         "metaPixel",
    //         "maxmind",
    //         "clarity",
    //         "googletagmanager",
    //         "googleapis",
    //         "LeadiD-ignore-element",
    //         "LeadiD-ignore-mutation",
    //         "_next",
    //         "trustedform",
    //         "bing",
    //         "lidstatic",
    //         "getclicky",
    //         "twitterPixel",
    //         "gppongmhjkpfnbhagpmjfkannfbllamg",
    //         "sc-static",
    //         "gstatic",
    //         "snap.licdn",
    //         "snapchat",
    //         "tiktok",
    //         "DummyFrame",
    //         "redditstatic.com",
    //         "doubleclick.net",
    //         "googleadservices.com",
    //     ];
    //     const tfIdPattern = /^tf\d*$/; //to remove any script id start with tf
    //     const callback = (mutationList: MutationRecord[]): void => {
    //         // Use traditional 'for loops' for IE 11
    //         for (const mutation of mutationList) {
    //             mutation.addedNodes?.forEach((node: Element) => {
    //                 if (
    //                     node.nodeName === "SCRIPT" &&
    //                     !tracingIgnoredIds.some((item) =>
    //                         node.getAttribute("src")?.includes(item),
    //                     ) &&
    //                     !tracingIgnoredIds.some((item) =>
    //                         node.getAttribute("id")?.includes(item),
    //                     ) &&
    //                     !tfIdPattern.test(node.getAttribute("id") as string)
    //                 ) {
    //                     logDomChanges({
    //                         tagName: node.nodeName,
    //                         outerHTML: node.outerHTML,
    //                         type: "ADD",
    //                     });
    //                 }
    //                 if (
    //                     node.nodeName === "IFRAME" &&
    //                     !tracingIgnoredIds.some((item) =>
    //                         node.getAttribute("class")?.includes(item),
    //                     ) &&
    //                     !tracingIgnoredIds.some((item) =>
    //                         node.getAttribute("src")?.includes(item),
    //                     ) &&
    //                     !tracingIgnoredIds.some((item) =>
    //                         node.getAttribute("name")?.includes(item),
    //                     )
    //                 ) {
    //                     logDomChanges({
    //                         tagName: node.nodeName,
    //                         outerHTML: node.outerHTML,
    //                         type: "ADD",
    //                     });
    //                 }
    //             });
    //         }
    //     };

    //     const observer = new MutationObserver(callback);
    //     if (targetNode) {
    //         observer.observe(targetNode, config);
    //         observer.observe(headTargetNode as Node, config);
    //     }
    // };

    useEffect(() => {
        if (router.query.lander) {
            localStorage.setItem("lander", router.query.lander as string);
        }
        setGoToThankYouPage(props.goToThankYouPage ?? false);
        // if (
        //     window.MutationObserver ||
        //     window.WebKitMutationObserver ||
        //     window.MozMutationObserver
        // ) {
        //     initTracing();
        // }
    }, []);

    const compactOfferBlock = domain.defaultOffers?.find(
        (offer) => offer.category?.slug === category?.slug,
    );
    const { form: formAsProp } = props;
    const finalForm = formAsProp ?? form;
    if (finalForm) {
        const {
            classes,
            colors,
            showProgress,
            backButton,
            sellingPoint,
            sellingPointsSteps,
            preventWindowClose,
            progressShift,
            stepsConfig,
            goToThankYouPage,
            dynamicThankYouFields,
            // PopupOffer,
            scrollToTop = true,
            modalSize,
            currentCategorySlug,
            popupStyle,
            hideOffers,
            customThankYouStep,
            isSecondServiceForm,
            isPiiCompletionForm,
            secondServiceColors,
            secondServiceClasses,
            updateSecondServices,
            tags,
            activeSecondServices,
            secondServiceLoading,
            showStepNumberOnly,
            formTemplateProp,
            resetLeadId = false,
            searchableMultiSelect,
            secondServiceSearchableMultiSelect,
            onModalClickClose,
            ignoreCategoryInThankYouPagePath = false,
            isFormInsideOffersModal = false,
            goToListPage,
        } = props;

        const {
            handleFieldBlur,
            handleFieldChange,
            handleSubmit,
            handlePrevStep,
            formId,
            formError,
            jornayaIdElement,
            steps,
            currentStep,
            isLoading,
            fieldsData,
            successMessage,
            handleOutsideFormSubmit,
            formSubmitted,
            otp,
            checkIfStepHasOneField,
            visitedSteps,
            formErrors,
            formErrorsResolved,
            showExitModal,
            stepStatus,
            showRegulations,
            setLoading,
            setHasRegulations,
            showCustomListingModal,
            setShowCustomListingModal,
            showPiiCompletionOtherFields,
            setShowPiiCompletionOtherFields,
            showFormModal,
            toggleFormModal,
            listingSlug,
            toggleShowFormInExitModal,
            showFromInExitModal,
            handleCloseFormInsideExitModal,
        } = useForm(
            finalForm,
            isSecondServiceForm,
            isPiiCompletionForm,
            goToListPage,
            resetLeadId,
            scrollToTop,
            !!props.showInModal,
            isFormInsideOffersModal,
        );

        const handleOffersPopupVisibility = () => {
            if (
                domain.config?.showFormInsideOffersModal &&
                !isFormInsideOffersModal &&
                popupShowCount === 0
            ) {
                toggleShowFormInExitModal(true);
                popupShowCount++;
                return;
            }
            const offers = exitModalOffer?.filter((offer) => {
                if (
                    (!category?.slug &&
                        props.currentCategorySlug &&
                        offer.category.slug === props.currentCategorySlug) ||
                    (category?.slug && offer.category.slug === category?.slug)
                ) {
                    return offer;
                }
            });

            if (
                offers?.length &&
                !offersPopupActive &&
                popupShowCount === 0 &&
                !showFromInExitModal
            ) {
                setPopupOffers(offers);
                setOffersPopupActive(true && !showFormModal);
                popupShowCount++;
            }
        };

        useEffect(() => {
            if (isSecondServiceForm && formSubmitted) {
                setSecondServiceSubmitted(true);
            }
        }, [formSubmitted]);

        const isSecondServiceSelected = useMemo(() => {
            if (tags) {
                const tagsKeys = Object.keys(tags);
                let tagsSelected = false;
                tagsKeys.forEach((tag) => {
                    tagsSelected = tagsSelected || tags[tag].active;
                });
                return tagsSelected;
            }
            return false;
        }, [tags]);

        const displayExitModal = useMemo(() => {
            if (showExitModal) {
                return true;
            }
            return false;
        }, [showExitModal]);

        const displayFormInOfferModal = useMemo(() => {
            return showFromInExitModal;
        }, [showFromInExitModal]);

        const fetchInsuranceOffers = async () => {
            const crossSellingDomainFormId = domain.categories.find(
                (item) => item.slug === category?.slug,
            )?.crossSellingDomainFormId;
            if (crossSellingDomainFormId) {
                setLoading(true);
                const { data: clicksSearchList } = await getClicksSearchList({
                    questions: {
                        zipCode: {
                            value: fieldsData["zipCode"].value,
                            name: "zipCode",
                        },
                    },
                    categorySlug: "auto-insurance",
                    crossSellingDomainFormId: crossSellingDomainFormId,
                });
                setLoading(false);
                return clicksSearchList;
            }
            return [];
        };
        const scrollToTopHandler = () => {
            if (scrollToTop) {
                window.scrollTo({
                    top: 22,
                    behavior: "smooth",
                });
            }
        };

        const fetchListingBySlug = async (slug: string) => {
            const { data } = await getListingBySlugV3({ slug });
            return data;
        };

        useEffect(() => {
            if (showRegulations) {
                fetchInsuranceOffers()
                    .then((res) => {
                        setOffersPopupActive(true);
                        setClicksOffers(res?.result as ClickOffer[]);
                        setListRequestId(res?.listRequestId as string);
                    })
                    .catch(() => {
                        setClicksOffers([]);
                        setOffersPopupActive(false);
                    });
            }
        }, [showRegulations]);

        useEffect(() => {
            if (showCustomListingModal && listingSlug) {
                fetchListingBySlug(listingSlug)
                    .then((res) => {
                        if (res && res.offers && res.offers.length) {
                            setOffersPopupActive(true);
                            setPopupOffers(res?.offers);
                        }
                    })
                    .catch(() => {
                        setPopupOffers([]);
                        setOffersPopupActive(false);
                    });
            }
        }, [showCustomListingModal]);

        useEffect(() => {
            if (displayExitModal || displayFormInOfferModal) {
                handleOffersPopupVisibility();
            }
        }, [displayExitModal, displayFormInOfferModal]);

        useEffect(() => {
            setSetPopupFormSubmittedFunction({
                function: handleOutsideFormSubmit,
            });

            document.body.addEventListener("mousemove", (e) => {
                const mouseY = e.clientY;
                if (
                    mouseY <= 10 &&
                    visitDetails?.trafficSourceNetwork !== "Propel Media"
                ) {
                    handleOffersPopupVisibility();
                }
            });

            if (
                preventWindowClose &&
                router.query.ts &&
                visitDetails?.trafficSourceNetwork !== "Propel Media"
            ) {
                window.addEventListener("beforeunload", handlePreventPageClose);
            }
        }, [currentCategorySlug]);

        const isLastStep: boolean = useMemo(() => {
            return currentStep === steps?.length - 1;
        }, [currentStep, steps]);

        useEffect(() => {
            if (isLastStep) {
                window.removeEventListener(
                    "beforeunload",
                    handlePreventPageClose,
                );
            }
        }, [isLastStep]);

        const goToThankYou = useCallback(
            (queryString, params) => {
                const isSingleCategory = domain?.categories?.length === 1;
                const categorySlug = category ? `${category.slug}/` : "";
                const activeLang = locale === "en" ? "" : `${locale}/`;

                const selectedCategory = domain.categories.find(
                    (categoryElement) =>
                        categoryElement.slug === form?.categorySlug,
                );
                let isSecondServiceSupported = false;
                if (
                    selectedCategory &&
                    selectedCategory?.secondServiceCategories?.length
                ) {
                    isSecondServiceSupported = true;
                }

                if (
                    isSingleCategory ||
                    isSecondServiceSupported ||
                    ignoreCategoryInThankYouPagePath
                ) {
                    return `${
                        window.location.origin
                    }/${activeLang}thank-you?data=${btoa(
                        JSON.stringify(params),
                    )}&zc=${fieldsData["zipCode"]?.value}&${
                        queryString as string
                    }`;
                }
                return `${
                    window.location.origin
                }/${activeLang}${categorySlug}thank-you?data=${btoa(
                    JSON.stringify(params),
                )}&zc=${fieldsData["zipCode"]?.value}&${queryString as string}`;
            },
            [domain, category, locale, fieldsData],
        );

        const saveGlobalAnswers = () => {
            if (!isSecondServiceForm) {
                const formAnswers = Object.keys(fieldsData).reduce(
                    (res, key) => {
                        res = {
                            ...res,
                            [key]: fieldsData[key].value,
                        };
                        return res;
                    },
                    {},
                );
                // @ts-ignore
                window.formAnswers = cloneDeep(formAnswers);
                localStorage.setItem(
                    "globalFormValues",
                    JSON.stringify(formAnswers),
                );
            }
        };

        useEffect(() => {
            const params: { [x: string]: string } = {};
            if (
                (formSubmitted &&
                    goToThankYouPage &&
                    !otp.isRequired &&
                    (!category?.secondServiceCategories ||
                        category?.secondServiceCategories.length === 0 ||
                        isSecondServiceForm)) ||
                (skipToThankYou && goToThankYouPage)
            ) {
                const queryString = gettingQueriesAsString(
                    router.query,
                    "start",
                );
                if (dynamicThankYouFields && !isSecondServiceForm) {
                    dynamicThankYouFields.forEach((field) => {
                        params[field] = fieldsData[field]?.value;
                    });
                }

                window.location.href = goToThankYou(queryString, params);
            }
        }, [
            formSubmitted,
            router,
            goToThankYouPage,
            dynamicThankYouFields,
            skipToThankYou,
            category,
        ]);
        useEffect(() => {
            const params: { [x: string]: string } = {};
            if (dynamicThankYouFields && !isSecondServiceForm) {
                dynamicThankYouFields.forEach((field) => {
                    params[field] = fieldsData[field]?.value;
                });
                localStorage.setItem("dynamicFields", JSON.stringify(params));
            }
            if (isPiiCompletionForm) {
                piiCompletionData?.fields.forEach((field) => {
                    params[field.codeName] = fieldsData[field.codeName]?.value;
                });
                localStorage.setItem("dynamicFields", JSON.stringify(params));
            }
            saveGlobalAnswers();
        }, [fieldsData]);

        useEffect(() => {
            if (!isSecondServiceForm) {
                localStorage.setItem(
                    "successMessage",
                    JSON.stringify(successMessage),
                );
            }
        }, []);

        const slideFormValue: number = useMemo(() => {
            if (formSubmitted && !goToThankYouPage && !goToListPage) {
                return currentStep * -100 - 300;
            }

            if (
                (isLastStep && formErrors) ||
                (formErrorsResolved && !otp?.isRequired && !otp?.isResolved)
            ) {
                return currentStep * -100 - 100;
            }

            if (otp?.isRequired || (otp?.isResolved && goToThankYouPage)) {
                return currentStep * -100 - 200;
            }

            // if (formSubmitted && (goToThankYouPage || goToListPage)) {
            //     return currentStep * -100;
            // }

            return currentStep * -100;
        }, [
            formSubmitted,
            goToThankYouPage,
            isLastStep,
            otp,
            currentStep,
            formErrorsResolved,
        ]);

        const showOtp =
            otp?.isRequired || (!otp?.isRequired && otp?.isResolved);

        const showTCPAMessage: boolean = useMemo(() => {
            return (
                steps &&
                steps[currentStep]?.includeTCPAMessage &&
                !formSubmitted &&
                !otp?.isRequired
            );
        }, [formSubmitted, otp]);

        const showBackButton: boolean | undefined = useMemo(() => {
            return (
                (currentStep !== 0 && !formSubmitted && !otp.isRequired) ||
                (backButton?.firstStepAction && currentStep === 0)
            );
        }, [backButton, currentStep, steps, formSubmitted]);

        type FieldWithValue = Partial<Field> & FieldData;

        const invalidFields: FieldWithValue[] = useMemo(() => {
            const fields = flatten(steps.map((step) => step.fields));

            if (formErrors) {
                const allFields: string[] = Object.keys(fieldsData);

                const invalidFieldsArray: FieldWithValue[] = [];

                allFields.forEach((field) => {
                    if (fieldsData[field].valid === false) {
                        invalidFieldsArray.push({
                            ...fieldsData[field],
                            ...(fields.find((f) => f.codeName === field) ?? {}),
                        });
                    }
                });

                return invalidFieldsArray;
            }
            return [];
        }, [formErrors]);

        const doesTheOfferHaveANumber = (offer: ListingItem): boolean => {
            return !!(
                offer?.proxiedNumber?.phoneNumber ||
                offer?.hiddenAttributes?.phoneNumber ||
                offer?.phoneValue ||
                offer?.attributes.phoneNumber
            );
        };

        const showFormInsideOffersModal = (): boolean => {
            return (
                !!domain.config?.showFormInsideOffersModal &&
                !isFormInsideOffersModal
            );
        };

        const modalOffersContent = useMemo(() => {
            if (
                Array.isArray(popupOffers) &&
                popupOffers.length === 1 &&
                doesTheOfferHaveANumber(popupOffers[0]) &&
                popupOffers[0]?.features?.length === 0
            ) {
                modalWidth = 500;
                return (
                    <PopupCallUs
                        popupStyle={popupStyle}
                        offerAsProp={popupOffers[0]}
                    />
                );
            }
            return popupOffers.map((offer) => {
                return (
                    <OfferBlock
                        zone={affiliateOfferZones.EXIT_MODAL}
                        offer={offer}
                        key={offer.id}
                        className={styles["offer"]}
                        displayLinkAndPhoneNumber={doesOfferHaveALink(offer)}
                    />
                );
            });
        }, [popupOffers]);

        const modalOfferClicksContent = useMemo(() => {
            if (showRegulations && clicksOffers && clicksOffers.length) {
                return (
                    <RegulationsContent
                        title="Hey Californians!"
                        description="Your state restricts extended auto
                                    warranties. You can get complete
                                    coverage by looking into mechanical
                                    breakdown insurance (MBI). Visit these
                                    websites to find out more:"
                    >
                        {clicksOffers?.slice(0, 2)?.map((clickOffer, index) => (
                            <InsuranceOffer
                                key={index}
                                index={index}
                                item={{
                                    ...clickOffer,
                                    slug: `${clickOffer.slug}?id=${listRequestId}`,
                                }}
                                category="Auto Insurance"
                                state={visitDetails?.region}
                                primaryColor="#f79626"
                                textColor="#266199"
                            />
                        ))}
                    </RegulationsContent>
                );
            }
        }, [clicksOffers]);

        const templateGlobalConfig = domain?.templateConfig;

        const modalVariables =
            typeof templateGlobalConfig?.headerAndFooter === "object" &&
            typeof templateGlobalConfig?.headerAndFooter.modals === "object"
                ? generateGlobalVariables({
                      modals: templateGlobalConfig?.headerAndFooter.modals,
                  })
                : {};

        const showOfferInForm =
            currentStep === 0 &&
            !!formOffer?.length &&
            (formTemplate === "v1" ||
                formTemplate === "v2" ||
                formTemplateProp);

        const isExpandable: (field: Field) => boolean = function (
            field: Field,
        ): boolean {
            const metaKeys: string[] = field.meta
                ? Object.keys(field.meta)
                : [];
            // check if field has expandable meta key
            return Array.isArray(metaKeys) && metaKeys.length
                ? metaKeys[0] === "expandable"
                    ? true
                    : false
                : false;
        };

        const getExpandableFields: (field: Field) => Field[] = function (
            field: Field,
        ): Field[] {
            const filteredExpandableFields = Object.keys(
                JSON.parse(
                    field.meta?.expandable ?? "[]",
                ) as ExpandableFieldMeta,
            ).filter((f) => f !== field.codeName);

            const allFields: string[] = Object.keys(fieldsData);
            const arrayOfExpandableFields: Field[] = [];
            allFields.forEach((codeName) => {
                if (filteredExpandableFields.includes(codeName)) {
                    // get expandable field object
                    arrayOfExpandableFields.push(fieldsData[codeName]);
                }
            });

            return arrayOfExpandableFields;
        };

        const isSecondServiceSupported = useMemo(() => {
            const selectedCategory = domain.categories.find(
                (categoryElement) => categoryElement.slug === category?.slug,
            );
            if (
                selectedCategory &&
                selectedCategory?.secondServiceCategories?.length
            ) {
                return true;
            }
            return false;
        }, [category]);

        const tcpa = useMemo(() => {
            const secondStepButtonText = form?.textBlocks?.find(
                (item) =>
                    item.position ===
                    "form-inside-exit-modal-submit-button-text-second-step",
            );
            const domainTcpa = domain?.textBlocks?.find(
                (textEl) => textEl.position === "custom-domain-tcpa",
            )?.text;

            return (isSecondServiceForm && isSecondServiceSelected) ||
                (!isSecondServiceForm && showTCPAMessage && !formErrors) ? (
                <TCPA
                    content={
                        (domainTcpa
                            ? domainTcpa
                            : finalForm.tCPAMessages
                            ? finalForm.tCPAMessages
                            : steps[currentStep].TCPAMessage) ?? ""
                    }
                    className={classes?.tcpaClassName ?? ""}
                    buttonText={
                        secondStepButtonText?.text && isFormInsideOffersModal
                            ? extractContentFromHtml(
                                  secondStepButtonText.text,
                              ).trim()
                            : steps[currentStep]?.buttonText
                    }
                />
            ) : (
                <></>
            );
        }, [
            isSecondServiceForm,
            isSecondServiceSelected,
            showTCPAMessage,
            formErrors,
        ]);

        const { formSubmitted: isMainFormSubmitted } = useFormContext();

        if (
            (skipToThankYou && !goToThankYouPage) ||
            (formSubmitted && isSecondServiceForm && !goToThankYouPage)
        ) {
            scrollToTopHandler();
            return (
                <div className={`${classes?.formClassName as string} mx-auto`}>
                    {compactOfferBlock && !hideOffers && (
                        <CompactOffer
                            zone={affiliateOfferZones.THANK_YOU}
                            showAllOffers
                        />
                    )}

                    {customThankYouStep ?? (
                        <ThankYou
                            inForm={true}
                            className={classes?.innerThankYouClassName ?? ""}
                        />
                    )}
                </div>
            );
        }

        if (
            isSecondServiceSupported &&
            isMainFormSubmitted &&
            !isPiiCompletionForm &&
            !isSecondServiceForm &&
            !showFormModal
        ) {
            return (
                <SecondServiceForms
                    classes={secondServiceClasses}
                    colors={{ ...colors, ...secondServiceColors }}
                    goToThankYou={goToThankYouPage ? true : false}
                    secondServiceSearchableMultiSelect={
                        secondServiceSearchableMultiSelect
                    }
                />
            );
        }

        const finalAnswerSummaryConfigs = domain.config
            ?.preDefinedAnswerSummaryConfigs
            ? preDefinedAnswerSummaryConfigs[
                  domain.config?.preDefinedAnswerSummaryConfigs
              ]
            : undefined;

        return (
            <>
                {finalForm ? (
                    <>
                        {finalForm.testConfig?.config?.css && (
                            <style
                                dangerouslySetInnerHTML={{
                                    __html: finalForm.testConfig?.config?.css,
                                }}
                            />
                        )}

                        <form
                            {...(domain.name === "eliteinjuryadvocates.com"
                                ? { "data-tf-element-role": "offer" }
                                : {})} // Conditionally add `data-tf-element-role` attribute
                            onSubmit={(e) => {
                                handleSubmit(e, {
                                    isSecondForm: isSecondServiceForm
                                        ? true
                                        : false,
                                });
                            }}
                            className={`${classes?.formClassName ?? ""} ${
                                styles["form"]
                            }`}
                            style={{
                                color: colors.textColor,
                                ...(showFormModal
                                    ? {
                                          backgroundColor: "transparent",
                                          boxShadow: "none",
                                      }
                                    : {}),
                            }}
                            noValidate
                            id={`form_${formId}`}
                            method="post"
                        >
                            {showOfferInForm &&
                                (formTemplate === "v1" ||
                                    formTemplateProp === "v1") && (
                                    <OffersInForm
                                        offer={
                                            formOffer[0]
                                                ? formOffer[0]
                                                : undefined
                                        }
                                        shape={formTemplate || formTemplateProp}
                                    />
                                )}

                            <FieldWrapper
                                type={showFormModal ? "modal" : "div"}
                                classes={classes}
                                current={currentStep}
                                total={
                                    formSubmitted
                                        ? steps?.length
                                        : steps?.length + 1
                                }
                                colors={colors}
                                shift={progressShift}
                                showProgress={
                                    showProgress &&
                                    !otp?.isRequired &&
                                    !formErrors &&
                                    !showOfferInForm
                                }
                                showStepNumberOnly={showStepNumberOnly}
                                showBackButton={
                                    showBackButton && !formErrors && !showOtp
                                }
                                handlePrevStep={handlePrevStep}
                                backButton={backButton}
                                onModalCloseClick={() => {
                                    toggleFormModal(false);
                                    onModalClickClose
                                        ? onModalClickClose()
                                        : null;
                                }}
                                tcpa={tcpa}
                            >
                                <div
                                    style={{
                                        transform: `translate(${slideFormValue}%, 0)`,
                                    }}
                                    className={`${styles["steps-wrapper"]} `}
                                    id="stepsWrapper"
                                >
                                    {steps?.map((step, stepIndex) => (
                                        <FormStep
                                            isSecondServiceForm={
                                                isSecondServiceForm
                                            }
                                            formButtonClassName={
                                                classes?.formButtonClassName
                                            }
                                            isFormInsideOffersModal={
                                                isFormInsideOffersModal
                                            }
                                            formErrors={formErrors}
                                            key={stepIndex}
                                            step={step}
                                            index={stepIndex}
                                            handleFieldBlur={handleFieldBlur}
                                            handleFieldChange={
                                                handleFieldChange
                                            }
                                            currentStep={currentStep}
                                            isLoading={isLoading}
                                            formError={formError}
                                            fieldsData={fieldsData}
                                            steps={steps}
                                            visitedSteps={visitedSteps}
                                            {...props}
                                            hideStepTitle={
                                                stepsConfig
                                                    ? stepsConfig[stepIndex]
                                                          ?.hideTitle ?? false
                                                    : false
                                            }
                                            hideStepDescription={
                                                stepsConfig
                                                    ? stepsConfig[stepIndex]
                                                          ?.hideDescription ??
                                                      false
                                                    : false
                                            }
                                            forceStepTitle={
                                                stepsConfig
                                                    ? stepsConfig[stepIndex]
                                                          ?.forceStepTitle
                                                    : false
                                            }
                                            checkIfStepHasOneField={
                                                checkIfStepHasOneField
                                            }
                                            formSubmitted={formSubmitted}
                                            formId={formId}
                                            form={finalForm}
                                            overrideStepTitle={
                                                stepsConfig
                                                    ? stepsConfig[stepIndex]
                                                          ?.title
                                                    : ""
                                            }
                                            overrideStepDescription={
                                                stepsConfig
                                                    ? stepsConfig[stepIndex]
                                                          ?.description
                                                    : ""
                                            }
                                            stepStatus={stepStatus}
                                            textBlocks={form?.textBlocks ?? []}
                                            updateSecondServices={
                                                updateSecondServices
                                            }
                                            tags={tags}
                                            activeSecondServices={
                                                activeSecondServices
                                            }
                                            secondServiceLoading={
                                                secondServiceLoading
                                            }
                                            showPiiCompletionOtherFields={
                                                showPiiCompletionOtherFields
                                            }
                                            setShowPiiCompletionOtherFields={
                                                setShowPiiCompletionOtherFields
                                            }
                                            searchableMultiSelect={
                                                searchableMultiSelect
                                            }
                                            answerSummaryConfigs={
                                                finalAnswerSummaryConfigs
                                            }
                                        />
                                    ))}

                                    {/* Start Extra steps */}
                                    <div className={styles["step"]}>
                                        <div>
                                            {/* Start all steps errors */}

                                            <div
                                                className={
                                                    styles["extra-step-inner"]
                                                }
                                            >
                                                {(formErrors ||
                                                    (formErrorsResolved &&
                                                        goToThankYouPage &&
                                                        !otp?.isRequired)) && (
                                                    <>
                                                        <div
                                                            className={`${styles["warningMessage"]} rounded-sm`}
                                                            id="all-errors"
                                                        >
                                                            <h1
                                                                className={`text-base sm:text-lg flex items-start sm:items-center justify-start sm:justify-center`}
                                                            >
                                                                <span className="px-1 pt-1 sm:pt-0">
                                                                    <WarningAlertIcon />
                                                                </span>{" "}
                                                                <span>
                                                                    Your
                                                                    Submission
                                                                    Has a Few
                                                                    Errors
                                                                </span>
                                                            </h1>
                                                            <p
                                                                className={`${styles["description"]}  text-xs sm:text-base `}
                                                            >
                                                                You are almost
                                                                done! Please
                                                                correct the
                                                                following fields
                                                                so we can
                                                                process your
                                                                request:
                                                            </p>
                                                        </div>
                                                        <div
                                                            className={`${
                                                                styles["fields"]
                                                            } ${
                                                                classes?.fieldsWrapperClassName ??
                                                                ""
                                                            }`}
                                                        >
                                                            {invalidFields.map(
                                                                (field) => (
                                                                    <FormField
                                                                        key={
                                                                            field.codeName
                                                                        }
                                                                        field={
                                                                            field as Field
                                                                        }
                                                                        fields={
                                                                            isExpandable(
                                                                                field as Field,
                                                                            )
                                                                                ? getExpandableFields(
                                                                                      field as Field,
                                                                                  )
                                                                                : []
                                                                        }
                                                                        asTitle={
                                                                            false
                                                                        }
                                                                        {...props}
                                                                        handleFieldBlur={
                                                                            handleFieldBlur
                                                                        }
                                                                        handleFieldChange={
                                                                            handleFieldChange
                                                                        }
                                                                        fieldsData={
                                                                            fieldsData
                                                                        }
                                                                        form={
                                                                            finalForm
                                                                        }
                                                                        searchableMultiSelect={
                                                                            searchableMultiSelect
                                                                        }
                                                                    />
                                                                ),
                                                            )}
                                                            {formErrorsResolved &&
                                                                goToThankYouPage &&
                                                                formErrors ===
                                                                    0 && (
                                                                    <div className="text-center">
                                                                        <div
                                                                            className={
                                                                                styles[
                                                                                    "line-1"
                                                                                ]
                                                                            }
                                                                        ></div>
                                                                        <div
                                                                            className={
                                                                                styles[
                                                                                    "line-2"
                                                                                ]
                                                                            }
                                                                        ></div>
                                                                        <div
                                                                            className={
                                                                                styles[
                                                                                    "line-2"
                                                                                ]
                                                                            }
                                                                        ></div>
                                                                        <div
                                                                            className={
                                                                                styles[
                                                                                    "line-3"
                                                                                ]
                                                                            }
                                                                        ></div>
                                                                    </div>
                                                                )}
                                                        </div>

                                                        <button
                                                            style={{
                                                                backgroundColor:
                                                                    colors.primaryColor,
                                                            }}
                                                            className={`${
                                                                styles[
                                                                    "submit-button"
                                                                ]
                                                            } ${
                                                                classes?.formButtonClassName ??
                                                                ""
                                                            } ${
                                                                isLastStep
                                                                    ? classes?.formSubmitButtonClassName ??
                                                                      ""
                                                                    : ""
                                                            }`}
                                                            type="submit"
                                                        >
                                                            {isLoading ||
                                                            (isLastStep &&
                                                                formSubmitted) ? (
                                                                <Loader
                                                                    size={30}
                                                                />
                                                            ) : (
                                                                <>
                                                                    <span>
                                                                        Submit
                                                                    </span>
                                                                    {currentStep ===
                                                                        0 && (
                                                                        <span
                                                                            className={`${styles["arrow"]}`}
                                                                        >
                                                                            <ArrowRightIcon />
                                                                        </span>
                                                                    )}
                                                                </>
                                                            )}
                                                        </button>
                                                    </>
                                                )}
                                            </div>

                                            {/* End all steps errors */}
                                        </div>
                                    </div>
                                    {/* End Extra steps */}

                                    {/* Start Extra steps */}
                                    <div className={styles["step"]}>
                                        <div>
                                            {/* otp step */}

                                            <div
                                                className={
                                                    styles["extra-step-inner"]
                                                }
                                            >
                                                {showOtp && (
                                                    <OtpForm
                                                        phoneNumber={
                                                            fieldsData
                                                                ?.phoneNumber
                                                                .value
                                                        }
                                                        colors={colors}
                                                        classes={classes}
                                                        isLoading={isLoading}
                                                        otp={otp}
                                                        handleFieldChange={
                                                            handleFieldChange
                                                        }
                                                    />
                                                )}
                                            </div>

                                            {/* otp step */}
                                        </div>
                                    </div>
                                    {/* End Extra steps */}

                                    {/* Start Extra steps */}
                                    <div className={styles["step"]}>
                                        <div>
                                            {/* thank you step */}

                                            <div
                                                className={
                                                    styles["extra-step-inner"]
                                                }
                                            >
                                                {formSubmitted &&
                                                    !goToThankYouPage &&
                                                    !goToListPage && (
                                                        <>
                                                            {compactOfferBlock &&
                                                                !hideOffers && (
                                                                    <CompactOffer
                                                                        zone={
                                                                            affiliateOfferZones.THANK_YOU
                                                                        }
                                                                        showAllOffers
                                                                    />
                                                                )}

                                                            {customThankYouStep ?? (
                                                                <ThankYou
                                                                    inForm={
                                                                        true
                                                                    }
                                                                    className={
                                                                        classes?.innerThankYouClassName
                                                                    }
                                                                />
                                                            )}
                                                        </>
                                                    )}
                                            </div>

                                            {/* thank you step */}
                                        </div>
                                    </div>
                                    {/* End Extra steps */}
                                </div>
                            </FieldWrapper>
                            {jornayaIdElement}

                            {sellingPoint &&
                                !isLastStep &&
                                (sellingPointsSteps === "ALL_STEPS" ||
                                    (sellingPointsSteps === "FIRST_STEP" &&
                                        currentStep === 0)) && (
                                    <div
                                        className={`${styles["sellingPoint"]} ${
                                            classes?.sellingPointClassName ?? ""
                                        }`}
                                    >
                                        {sellingPoint}
                                    </div>
                                )}

                            {showProgress &&
                                !otp?.isRequired &&
                                !formErrors &&
                                !showOfferInForm &&
                                !showFormModal &&
                                !stepsConfig?.[currentStep]?.hideProgress && (
                                    <ProgressBar
                                        className={`${styles["progress-bar"]} ${
                                            classes?.formProgressbarClassName ??
                                            ""
                                        }`}
                                        current={currentStep}
                                        total={
                                            formSubmitted
                                                ? steps?.length
                                                : steps?.length + 1
                                        }
                                        color={colors?.progressBar}
                                        shift={progressShift}
                                        showStepNumberOnly={showStepNumberOnly}
                                    />
                                )}
                            {showOfferInForm &&
                                (formTemplate === "v2" ||
                                    formTemplateProp === "v2") && (
                                    <OffersInForm
                                        offer={
                                            formOffer[0]
                                                ? formOffer[0]
                                                : undefined
                                        }
                                        shape={
                                            (formTemplate as string) ||
                                            formTemplateProp
                                        }
                                    />
                                )}

                            {!showFormModal && tcpa}
                            {/* {showTCPAMessage && !formErrors && (
                                <TCPA
                                    content={
                                        (finalForm.tCPAMessages
                                            ? finalForm.tCPAMessages
                                            : steps[currentStep].TCPAMessage) ??
                                        ""
                                    }
                                    className={classes?.tcpaClassName ?? ""}
                                />
                            )} */}
                            {/* +1 is for thank you step */}

                            {showBackButton &&
                                !formErrors &&
                                !showOtp &&
                                !showFormModal && (
                                    <button
                                        type="button"
                                        className={`${styles["back-button"]} ${
                                            backButton?.class ?? ""
                                        }`}
                                        onClick={() =>
                                            backButton?.firstStepAction &&
                                            currentStep === 0
                                                ? backButton.firstStepAction()
                                                : handlePrevStep()
                                        }
                                    >
                                        {backButton?.label ?? "Previous"}
                                        <i className={styles["arrow-back"]}></i>
                                    </button>
                                )}

                            <Modal
                                active={offersPopupActive}
                                onClose={() => {
                                    setOffersPopupActive(false);
                                    if (showRegulations) {
                                        setClicksOffers([]);
                                        setHasRegulations(false);
                                    }
                                    if (showCustomListingModal) {
                                        setPopupOffers([]);
                                        setShowCustomListingModal(false);
                                    }
                                }}
                                closeIcon={<CloseIcon />}
                                width={modalSize ?? modalWidth}
                                modalClassName={styles["form-offers-popup"]}
                                pageBuilderVariables={
                                    modalVariables as CSSProperties
                                }
                            >
                                {modalOffersContent}
                                {modalOfferClicksContent}
                            </Modal>
                            {showFormInsideOffersModal() && (
                                <Modal
                                    active={showFromInExitModal}
                                    onClose={() => {
                                        handleCloseFormInsideExitModal();
                                    }}
                                    closeIcon={<CloseIcon />}
                                    width={1000}
                                    modalClassName={
                                        styles["form-inside-offers-container"]
                                    }
                                    pageBuilderVariables={
                                        modalVariables as CSSProperties
                                    }
                                >
                                    {showFromInExitModal && (
                                        <FormOfferModal {...props} />
                                    )}
                                </Modal>
                            )}
                        </form>
                    </>
                ) : null}
            </>
        );
    }

    return <></>;
}

interface FormStepProps
    extends FormProps,
        Pick<
            UseFormHook,
            | "handleFieldBlur"
            | "handleFieldChange"
            | "currentStep"
            | "isLoading"
            | "formError"
            | "fieldsData"
        > {
    step: Step;
    index: number;
    steps: Step[];
    visitedSteps: {};
    hideStepTitle?: boolean;
    hideStepDescription?: boolean;
    forceStepTitle?: boolean;
    formSubmitted?: boolean;
    formId: number;
    stepStatus: string;
    checkIfStepHasOneField: (fieldsData: {
        [x: string]: {
            value: string;
            valid: boolean;
            errorMessage: string;
        };
    }) => boolean;
    overrideStepTitle?: string | undefined;
    overrideStepDescription?: string | undefined;
    hideOffers?: boolean;
    isSecondServiceForm?: boolean;
    isFormInsideOffersModal: boolean;
    formButtonClassName?: string;
    form: DomainForm;
    textBlocks: ITextBlock[];
    updateSecondServices?: (slug: string) => void;
    tags?: { [x: string]: { slug: string; active: boolean } };
    activeSecondServices?: string[];
    secondServiceLoading?: boolean;
    showPiiCompletionOtherFields: boolean;
    setShowPiiCompletionOtherFields: (value: boolean) => void;
    showFormModal?: boolean;
}

const FormStep = (props: FormStepProps): ReactElement => {
    const { setSkipToThankYou } = useFormContext();

    const { width } = useWindowSize();
    const [isSubmitStepButtonBlocked, setSubmitStepButtonBlocked] =
        useState(true);
    const {
        step,
        index,
        colors,
        classes,
        handleFieldBlur,
        handleFieldChange,
        currentStep,
        isLoading,
        formError,
        fieldsData,
        form,
        customMainTitle,
        steps,
        visitedSteps,
        hideStepTitle,
        hideStepDescription,
        forceStepTitle,
        checkIfStepHasOneField,
        formSubmitted,
        formId,
        overrideStepTitle,
        overrideStepDescription,
        stepStatus,
        formErrors,
        isSecondServiceForm,
        formButtonClassName,
        textBlocks,
        updateSecondServices,
        tags,
        isPiiCompletionForm,
        showPiiCompletionOtherFields,
        setShowPiiCompletionOtherFields,
        searchableMultiSelect,
        showFormModal,
        answerSummaryConfigs,
        isFormInsideOffersModal,
    } = props;

    const {
        formattedTitle,
        visitDetails,
        domain,
        category,
        piiCompletionData,
    } = useDomainContext();
    const [buttonDisabled, setButtonDisabled] = useState({
        getQuote: false,
        noThankYou: false,
    });
    const isSecondServiceSelected = useMemo(() => {
        if (tags) {
            const tagsKeys = Object.keys(tags);
            let tagsSelected = false;
            tagsKeys.forEach((tag) => {
                tagsSelected = tagsSelected || tags[tag].active;
            });
            return tagsSelected;
        }
        return false;
    }, [tags]);
    const isLastStep: boolean = useMemo(() => {
        return currentStep === steps?.length - 1;
    }, [currentStep, steps]);

    const stepTitle = replacePlaceholders(step.title, {
        region: visitDetails.region,
        city: visitDetails?.city,
        category: category?.label ?? category?.name,
    });

    useEffect(() => {
        setSubmitStepButtonBlocked(
            (isSecondServiceForm && !isSecondServiceSelected) ||
                buttonDisabled.getQuote ||
                isLoading,
        );
    }, [
        isSecondServiceForm,
        isSecondServiceSelected,
        buttonDisabled,
        isLoading,
    ]);

    const checkIfShowNextButton = () => {
        if (
            checkIfStepHasOneField(fieldsData) ||
            (form.testConfig?.config?.mergeFirstInput && !showFormModal)
        ) {
            return null;
        }

        const firstStepButtonText = textBlocks?.find(
            (item) =>
                item.position === "form-inside-exit-modal-submit-button-text",
        );
        const secondStepButtonText = textBlocks?.find(
            (item) =>
                item.position ===
                "form-inside-exit-modal-submit-button-text-second-step",
        );
        const buttonText =
            currentStep === 0 ? firstStepButtonText : secondStepButtonText;

        return (
            <button
                style={{
                    backgroundColor:
                        colors.primaryColor ||
                        "var(--global-form-buttons-background-color)",
                }}
                className={`${styles["submit-button"]} ${
                    classes?.formButtonClassName ?? ""
                } ${
                    isLastStep ? classes?.formSubmitButtonClassName ?? "" : ""
                } ${isLastStep ? "final-step" : ""} ${
                    currentStep === 0 && isSubmitStepButtonBlocked // used for the first step to disable the submit button but without showing it.
                        ? `${styles["disable-without-opacity"]}` // because marketing team is claiming that the disabled button lowers the conversion!!
                        : ""
                }`}
                {...(isLastStep && domain.name === "eliteinjuryadvocates.com"
                    ? { "data-tf-element-role": "submit" }
                    : {})} // Conditionally add `data-tf-element-role` attribute
                type="submit"
                id="frmMainBtn"
                disabled={isSubmitStepButtonBlocked}
            >
                <div
                    className={
                        !isSecondServiceForm ? styles["progressBtn"] : ""
                    }
                ></div>
                {isLoading || (isLastStep && formSubmitted) ? (
                    <>
                        <Loader size={30} />{" "}
                        {isLastStep ? (
                            <span className={styles["submittingLabel"]}>
                                Submitting
                            </span>
                        ) : (
                            ""
                        )}
                    </>
                ) : (
                    <>
                        {isFormInsideOffersModal && buttonText ? (
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: buttonText.text,
                                }}
                            />
                        ) : (
                            <span>{step.buttonText ?? "Next"}</span>
                        )}
                        {currentStep === 0 && (
                            <span className={`${styles["arrow"]}`}>
                                <ArrowRightIcon />
                            </span>
                        )}
                    </>
                )}
            </button>
        );
    };

    const showMergedButton = () => {
        if (form.testConfig?.config?.mergeFirstInput && !showFormModal) {
            return (
                <button
                    style={{
                        backgroundColor:
                            colors.primaryColor ||
                            "var(--global-form-buttons-background-color)",
                    }}
                    className={`${styles["first-step-button-merged"]}  ${
                        classes?.formButtonClassName ?? ""
                    }`}
                    type="submit"
                    id="frmMainBtn"
                    disabled={isSubmitStepButtonBlocked}
                >
                    <div className="progressBtn"></div>
                    {isLoading ? (
                        <>
                            <Loader size={30} />{" "}
                            {isLastStep ? (
                                <span className={styles["submittingLabel"]}>
                                    Submitting
                                </span>
                            ) : (
                                ""
                            )}
                        </>
                    ) : (
                        <>
                            <span>{step.buttonText ?? "Next"}</span>
                            {currentStep === 0 && (
                                <span className={`${styles["arrow"]}`}>
                                    <ArrowRightIcon />
                                </span>
                            )}
                        </>
                    )}
                </button>
            );
        }
    };

    const getTitle = () => {
        if (formattedTitle && formattedTitle.length) {
            return formattedTitle;
        } else if (
            (textBlocks &&
                textBlocks.length &&
                textBlocks.find((item) => item.position === "form_title")) ||
            (isFormInsideOffersModal &&
                textBlocks.find(
                    (item) =>
                        item.position ===
                        "form-inside-exit-modal-first-step-title",
                ))
        ) {
            if (isFormInsideOffersModal) {
                return replacePlaceholders(
                    textBlocks.find(
                        (item) =>
                            item.position ===
                            "form-inside-exit-modal-first-step-title",
                    )?.text as string,
                    {
                        category: category?.name,
                        region: visitDetails.region,
                        city: visitDetails?.city,
                    },
                );
            }
            return replacePlaceholders(
                textBlocks.find((item) => item.position === "form_title")
                    ?.text as string,
                {
                    category: category?.name,
                    region: visitDetails.region,
                    city: visitDetails?.city,
                },
            );
        } else {
            return overrideStepTitle
                ? overrideStepTitle
                : forceStepTitle
                ? stepTitle
                : customMainTitle
                ? customMainTitle
                : stepTitle;
        }
    };
    const getTitleSource = () => {
        if (formattedTitle && formattedTitle.length) {
            return "url";
        } else if (
            textBlocks &&
            textBlocks.length &&
            textBlocks.find(
                (item) =>
                    item.position === "form_title" ||
                    item.position ===
                        "form-inside-exit-modal-first-step-title" ||
                    item.position ===
                        "form-inside-exit-modal-second-step-title",
            )
        ) {
            return "text_block";
        } else if (customMainTitle || overrideStepTitle) {
            return "code";
        } else {
            return "form";
        }
    };

    useEffect(() => {
        if (index === 0 && !isSecondServiceForm && !isPiiCompletionForm) {
            void sendVisitMeta({
                event: "formTitle",
                eventValue: getTitle(),
                eventCategory: "meta",
            });
            void sendVisitMeta({
                event: "formTitleSource",
                eventValue: getTitleSource(),
                eventCategory: "meta",
            });
        }
    }, []);

    // excludedFields = list of fields that should not displayed in the form step
    const excludedFields: string[] = useMemo(() => {
        const excludedFieldsArray: string[] = [];
        step.fields.forEach((field) => {
            const metaKeys: string[] = field.meta
                ? Object.keys(field.meta)
                : [];

            // check if field has expandable meta key
            const isExpandable =
                Array.isArray(metaKeys) && metaKeys.length
                    ? metaKeys[0] === "expandable"
                        ? true
                        : false
                    : false;
            if (isExpandable) {
                const expandableFields = JSON.parse(
                    field.meta?.expandable as string,
                ) as ExpandableFieldMeta;
                const fieldsCodeName = Object.keys(expandableFields);
                for (let i = 0; i < fieldsCodeName.length; i++) {
                    if (
                        expandableFields[fieldsCodeName[i]].ignoreField ===
                        "true"
                    ) {
                        excludedFieldsArray.push(fieldsCodeName[i]);
                    }
                }
            }
        });
        return excludedFieldsArray;
    }, [step.fields]);

    const fieldsList = [];
    const sharedFieldsList = [];
    const fieldsListLength = step.fields.length;
    for (let i = 0; i < fieldsListLength; i++) {
        if (!excludedFields.includes(step.fields[i].codeName)) {
            if (
                step.fields[i].tag === "common" ||
                step.fields[i].tag === "pii"
            ) {
                if (SHARED_FIELDS_CODENAME.includes(step.fields[i].codeName)) {
                    sharedFieldsList.push(
                        <div
                            style={
                                COMMON_FIELDS_CODENAME.includes(
                                    step.fields[i].codeName,
                                )
                                    ? { width: "100%" }
                                    : {}
                            }
                            key={i}
                        >
                            <FormField
                                key={i}
                                field={step.fields[i]}
                                fields={step.fields}
                                asTitle={
                                    step.fields.length === 1 && !step.title
                                }
                                {...props}
                                handleFieldBlur={handleFieldBlur}
                                handleFieldChange={handleFieldChange}
                                fieldsData={fieldsData}
                                visitedSteps={visitedSteps}
                                currentStep={currentStep}
                                colors={colors}
                                isLoading={isLoading}
                                isLastStep={isLastStep}
                                formSubmitted={formSubmitted}
                                stepStatus={stepStatus}
                                formId={formId}
                                form={form}
                                isSecondServiceForm={
                                    isSecondServiceForm as boolean
                                }
                                searchableMultiSelect={searchableMultiSelect}
                            />
                        </div>,
                    );
                } else {
                    sharedFieldsList.push(
                        <div style={{ width: "100%" }} key={i}>
                            <FormField
                                key={i}
                                field={step.fields[i]}
                                fields={step.fields}
                                asTitle={
                                    step.fields.length === 1 && !step.title
                                }
                                {...props}
                                handleFieldBlur={handleFieldBlur}
                                handleFieldChange={handleFieldChange}
                                fieldsData={fieldsData}
                                visitedSteps={visitedSteps}
                                currentStep={currentStep}
                                colors={colors}
                                isLoading={isLoading}
                                isLastStep={isLastStep}
                                formSubmitted={formSubmitted}
                                stepStatus={stepStatus}
                                formId={formId}
                                form={form}
                                isSecondServiceForm={
                                    isSecondServiceForm as boolean
                                }
                                searchableMultiSelect={searchableMultiSelect}
                            />
                        </div>,
                    );
                }
            } else {
                if (
                    SHARED_FIELDS_CODENAME.includes(step.fields[i].codeName) &&
                    !isPiiCompletionForm &&
                    !props.goToListPage
                ) {
                    sharedFieldsList.push(
                        <div
                            style={
                                COMMON_FIELDS_CODENAME.includes(
                                    step.fields[i].codeName,
                                )
                                    ? {
                                          width: "100%",
                                          ...(form.testConfig?.config
                                              ?.mergeFirstInput
                                              ? {
                                                    display: "flex",
                                                    alignItems: "center",
                                                    ...(width <= 767
                                                        ? {
                                                              flexDirection:
                                                                  "column",
                                                          }
                                                        : {}),
                                                }
                                              : {}),
                                      }
                                    : {}
                            }
                            key={i}
                        >
                            <FormField
                                key={i}
                                field={step.fields[i]}
                                fields={step.fields}
                                asTitle={
                                    step.fields.length === 1 && !step.title
                                }
                                {...props}
                                handleFieldBlur={handleFieldBlur}
                                handleFieldChange={handleFieldChange}
                                fieldsData={fieldsData}
                                visitedSteps={visitedSteps}
                                currentStep={currentStep}
                                colors={colors}
                                isLoading={isLoading}
                                isLastStep={isLastStep}
                                formSubmitted={formSubmitted}
                                stepStatus={stepStatus}
                                formId={formId}
                                form={form}
                                isSecondServiceForm={
                                    isSecondServiceForm as boolean
                                }
                                searchableMultiSelect={searchableMultiSelect}
                            />
                            {showMergedButton()}
                        </div>,
                    );
                } else {
                    if (
                        !piiCompletionData?.missingFields?.includes(
                            step.fields[i].codeName,
                        )
                    ) {
                        fieldsList.push(
                            <FormField
                                key={i}
                                field={step.fields[i]}
                                fields={step.fields}
                                asTitle={
                                    step.fields.length === 1 && !step.title
                                }
                                {...props}
                                handleFieldBlur={handleFieldBlur}
                                handleFieldChange={handleFieldChange}
                                fieldsData={fieldsData}
                                visitedSteps={visitedSteps}
                                currentStep={currentStep}
                                colors={colors}
                                isLoading={isLoading}
                                isLastStep={isLastStep}
                                formSubmitted={formSubmitted}
                                stepStatus={stepStatus}
                                formId={formId}
                                form={form}
                                isSecondServiceForm={
                                    isSecondServiceForm as boolean
                                }
                                searchableMultiSelect={searchableMultiSelect}
                            />,
                        );
                    }
                }
            }
        }
    }
    const getFieldsGroup = (fieldsList: JSX.Element[]) => {
        const groupByTag: { [x: string]: JSX.Element[] } = {};
        fieldsList.forEach((field) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (!groupByTag[field?.props?.field?.tag as string]) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                groupByTag[field?.props?.field?.tag as string] = [];
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            groupByTag[field?.props?.field?.tag as string].push(field);
        });
        const tagsElements = Object.keys(tags!);

        return tagsElements.map((tag) => {
            const categoryIcon = getSecondServiceCategoryIcon(
                tag,
                category as Category,
                form,
            ) as Image;

            return (
                <div key={tag}>
                    {tag !== "common" && (
                        <div
                            className={`${styles.serviceLabel} ${
                                tags![tag].active ? styles.activeService : ""
                            } flex items-center `}
                            onClick={() => {
                                if (updateSecondServices)
                                    updateSecondServices(tag);
                            }}
                        >
                            {categoryIcon && (
                                <div className="relative">
                                    <S3Icon
                                        image={categoryIcon}
                                        svgClassName={styles["category-icon"]}
                                    />
                                </div>
                            )}
                            <span>
                                {tag === "undefined" ? form.categoryName : tag}
                            </span>
                        </div>
                    )}
                    {tags![tag].active && (
                        <div className={styles["tagGroup"]}>
                            {groupByTag[tag]
                                ? groupByTag[tag].map((f) => f)
                                : groupByTag["undefined"]
                                ? groupByTag["undefined"].map((f) => f)
                                : []}
                        </div>
                    )}
                </div>
            );
        });
    };
    const extendAnswerSummaryConfig = answerSummaryConfigs?.find(
        (config) => config.extend,
    );

    const answerSummaryConfig =
        answerSummaryConfigs?.find((config) => config.stepIndex === index) ??
        (extendAnswerSummaryConfig &&
        currentStep > extendAnswerSummaryConfig.stepIndex
            ? extendAnswerSummaryConfig
            : undefined);

    return (
        <div
            className={`${styles["step"]} ${classes?.stepClassName ?? ""} ${
                index === 0 && !step.description && step.title
                    ? (classes?.firstStepClass as string)
                    : ""
            }`}
        >
            {currentStep === index && !formErrors && (
                <>
                    {answerSummaryConfig && (
                        <AnswerSummary
                            answerSummaryConfig={answerSummaryConfig}
                            fieldsState={fieldsData}
                        />
                    )}
                    {step.tag &&
                        !["pii", "common"].includes(step.tag) &&
                        !isSecondServiceForm && (
                            <div className={classes?.stepTagClassName ?? ""}>
                                {step.tag}
                            </div>
                        )}
                    {(step.title || customMainTitle) && !hideStepTitle ? (
                        index === 0 ? (
                            <>
                                <h1
                                    className={`${styles["title"]} ${
                                        classes?.stepTitleClassName ?? ""
                                    }`}
                                >
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: getTitle(),
                                        }}
                                    ></span>
                                    {step.tooltip && (
                                        <Tooltip
                                            placement="bottom"
                                            overlay={
                                                <span>{step.tooltip}</span>
                                            }
                                            trigger={["hover"]}
                                            overlayClassName={styles["tooltip"]}
                                        >
                                            <div
                                                className={
                                                    styles["tooltip-icon"]
                                                }
                                            >
                                                <TooltipIcon />
                                            </div>
                                        </Tooltip>
                                    )}
                                </h1>
                            </>
                        ) : (
                            <h2
                                className={`${styles["title"]} ${
                                    classes?.stepTitleClassName ?? ""
                                }`}
                            >
                                {isFormInsideOffersModal &&
                                textBlocks.find(
                                    (item) =>
                                        item.position ===
                                        "form-inside-exit-modal-second-step-title",
                                )?.text ? (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: replacePlaceholders(
                                                textBlocks.find(
                                                    (item) =>
                                                        item.position ===
                                                        "form-inside-exit-modal-second-step-title",
                                                )?.text as string,
                                                {
                                                    category: category?.name,
                                                    region: visitDetails.region,
                                                    city: visitDetails?.city,
                                                },
                                            ),
                                        }}
                                    />
                                ) : (
                                    <span>{step.title}</span>
                                )}
                                {step.tooltip && (
                                    <Tooltip
                                        placement="bottom"
                                        overlay={<span>{step.tooltip}</span>}
                                        trigger={["hover"]}
                                        overlayClassName={styles["tooltip"]}
                                    >
                                        <div className={styles["tooltip-icon"]}>
                                            <TooltipIcon />
                                        </div>
                                    </Tooltip>
                                )}
                            </h2>
                        )
                    ) : null}

                    {step.description &&
                        !hideStepDescription &&
                        !isFormInsideOffersModal && (
                            <div
                                className={`${styles["description"]} ${
                                    classes?.stepDescriptionClassName ?? ""
                                } ${
                                    hideStepTitle
                                        ? `${styles["title"]} ${
                                              classes?.stepTitleClassName ?? ""
                                          }`
                                        : ""
                                }`}
                            >
                                {index === 0 &&
                                textBlocks &&
                                textBlocks.length &&
                                textBlocks.find(
                                    (item) =>
                                        item.position === "form_description",
                                ) ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: textBlocks.find(
                                                (item) =>
                                                    item.position ===
                                                    "form_description",
                                            )?.text as string,
                                        }}
                                    ></div>
                                ) : overrideStepDescription ? (
                                    overrideStepDescription
                                ) : (
                                    step.description
                                )}
                            </div>
                        )}
                    {isPiiCompletionForm ? (
                        <>
                            <div className={`pii-completion-missing-fields`}>
                                <MissingFieldsHeadline />
                                {piiCompletionData?.missingFields?.map(
                                    (field) => (
                                        <div key={field}>
                                            <FormField
                                                field={
                                                    step.fields.find(
                                                        (i) =>
                                                            i.codeName ===
                                                            field,
                                                    ) as Field
                                                }
                                                fields={step.fields}
                                                asTitle={
                                                    step.fields.length === 1 &&
                                                    !step.title
                                                }
                                                {...props}
                                                handleFieldBlur={
                                                    handleFieldBlur
                                                }
                                                handleFieldChange={
                                                    handleFieldChange
                                                }
                                                fieldsData={fieldsData}
                                                visitedSteps={visitedSteps}
                                                currentStep={currentStep}
                                                colors={colors}
                                                isLoading={isLoading}
                                                isLastStep={isLastStep}
                                                formSubmitted={formSubmitted}
                                                stepStatus={stepStatus}
                                                formId={formId}
                                                form={form}
                                                isSecondServiceForm={false}
                                                searchableMultiSelect={
                                                    searchableMultiSelect
                                                }
                                            />
                                        </div>
                                    ),
                                )}

                                {!showPiiCompletionOtherFields && (
                                    <div
                                        onClick={() => {
                                            setShowPiiCompletionOtherFields(
                                                true,
                                            );
                                        }}
                                        className="text-[#848484] font-semibold text-center cursor-pointer mb-4"
                                    >
                                        <span className="text-[#328df8]">
                                            Edit
                                        </span>{" "}
                                        Your Answers
                                    </div>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                className={`${
                                    isSecondServiceForm
                                        ? styles["serviceListWrapper"]
                                        : ""
                                } ${styles["fields"]} ${
                                    classes?.fieldsWrapperClassName ?? ""
                                }`}
                            >
                                {isSecondServiceForm
                                    ? getFieldsGroup(fieldsList)
                                    : fieldsList}
                            </div>

                            <div
                                className={`
                                ${
                                    isSecondServiceForm
                                        ? styles["sharedFieldsContainer"]
                                        : ""
                                }
                                ${
                                    isSecondServiceForm &&
                                    !isSecondServiceSelected
                                        ? "hidden"
                                        : ""
                                }
                                    `}
                            >
                                <div
                                    className={`${styles["fields"]} ${
                                        classes?.fieldsWrapperClassName ?? ""
                                    } ${
                                        isSecondServiceForm
                                            ? `${styles["sharedFieldsWrapper"]} flex flex-wrap`
                                            : ""
                                    }`}
                                >
                                    {sharedFieldsList}
                                </div>
                            </div>
                        </>
                    )}

                    {isLastStep && !isSecondServiceForm ? <SmsTCPA /> : null}
                    <div className="flex flex-col md:gap-3  sm:flex-row justify-between items-center">
                        {isSecondServiceForm ? (
                            <div
                                className={`${styles["buttons"]} order-2 sm:order-1`}
                            >
                                <button
                                    className={` ${styles["submit-button"]} ${
                                        styles["form-button"]
                                    } ${formButtonClassName ?? ""}`}
                                    style={{
                                        border: `1px solid ${
                                            colors.primaryColor ||
                                            "var(--global-form-buttons-background-color)"
                                        }`,
                                        backgroundColor: "#fff",
                                        color: "#000",
                                    }}
                                    type="button"
                                    onClick={() => {
                                        setSkipToThankYou(true);
                                        if (isSecondServiceForm) {
                                            setButtonDisabled({
                                                noThankYou: false,
                                                getQuote: true,
                                            });
                                        }
                                    }}
                                    disabled={isLoading}
                                >
                                    No Thank You
                                </button>
                            </div>
                        ) : (
                            <></>
                        )}

                        <div
                            className={`${styles["buttons"]} order-1 sm:order-2`}
                        >
                            {isPiiCompletionForm &&
                                showPiiCompletionOtherFields && (
                                    <>
                                        <div className="flex flex-col">
                                            <OtherFieldsHeadline />

                                            <div
                                                className={`${
                                                    styles["fields"]
                                                } ${
                                                    classes?.fieldsWrapperClassName ??
                                                    ""
                                                } `}
                                            >
                                                {fieldsList}
                                            </div>
                                        </div>
                                    </>
                                )}
                            {checkIfShowNextButton()}
                        </div>
                    </div>

                    {formError && (
                        <div className={styles["form-error"]}>
                            <span>{formError}</span>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
// END OF FORM STEP

interface FieldProps
    extends FormProps,
        Pick<
            UseFormHook,
            "handleFieldBlur" | "handleFieldChange" | "fieldsData"
        > {
    asTitle: boolean;
    field: Field;
    fields?: Field[];
    visitedSteps?: {};
    currentStep?: number;
    isLoading?: boolean;
    isLastStep?: boolean;
    formSubmitted?: boolean;
    formId?: number;
    isExpandable?: boolean;
    stepStatus: string;
    isSecondServiceForm: boolean;
    searchableMultiSelect?: boolean;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const FormField = (props: FieldProps) => {
    const {
        handleFieldBlur,
        handleFieldChange,
        onKeyDown,
        asTitle,
        fieldsData,
        field,
        fields,
        classes,
        colors,
        currentStep,
        stepStatus,
        formId,
        isExpandable,
        ignoreOptionIcons,
        isSecondServiceForm,
        searchableMultiSelect,
    } = props;

    const {
        form,
        selectedCountryCode,
        updateSelectedCountryCode,
        locale,
        domain,
    } = useDomainContext();

    const { query } = useRouter();

    const getFieldType = (): FieldTypes => {
        const metaKeys: string[] = field.meta ? Object.keys(field.meta) : [];
        // check if field has expandable meta key
        const isExpandableField =
            Array.isArray(metaKeys) && metaKeys.length
                ? metaKeys.includes("expandable")
                    ? true
                    : false
                : false;

        if (isExpandableField) {
            const expandableFields = JSON.parse(
                field.meta?.expandable ?? "",
            ) as ExpandableFieldMeta;

            const keys = Object.keys(expandableFields);
            for (let i = 0; i < keys.length; i++) {
                if (keys[i] === field.codeName) {
                    field.fieldType = expandableFields[keys[i]]
                        ?.contentType as FieldTypes;

                    return expandableFields[keys[i]].contentType as FieldTypes;
                }
            }
            return field.fieldType;
        }
        return field.fieldType;
    };
    const getFieldErrorMessage = () => {
        if (
            selectedCountryCode === "CA" &&
            field.codeName === "zipCode" &&
            !fieldState.valid
        ) {
            return locale === "en"
                ? field.meta?.postalCodeErrorMsg ?? "Enter a Valid Postal Code"
                : field.meta?.postalCodeErrorMsgEs ??
                      "Ingrese un código postal válido";
        } else {
            return fieldState?.errorMessage;
        }
    };
    const decodeNote = (note: string | null) => {
        if (note) {
            const { meta } = field;
            if (meta && meta?.showCharCount && meta?.showCharCount === "true") {
                return note.replace(
                    /\[charCount\]/gi,
                    (
                        parseInt(field.maxValue?.value as string) -
                        fieldState.value.length
                    ).toString(),
                );
            }
            return note;
        }

        return note;
    };
    const getFieldLabel = () => {
        if (selectedCountryCode === "CA" && field.codeName === "zipCode") {
            return field.meta?.canadianLabel ?? "Postal Code";
        } else {
            return field.label;
        }
    };
    const fieldState = fieldsData[field.codeName];

    const gettingField = () => {
        const type = getFieldType();
        switch (type) {
            case "text":
            case "email":
            case "number":
            case "tel":
                return (
                    <Input
                        field={field}
                        onChange={(val: string) =>
                            handleFieldChange(field, val)
                        }
                        onBlur={() =>
                            !isSecondServiceForm ? handleFieldBlur(field) : null
                        }
                        value={fieldState?.value}
                        type={type}
                        className={`${classes?.fieldsClassName?.input ?? ""} ${
                            classes?.fieldsClassName?.all ?? ""
                        } ${
                            isSecondServiceForm ? styles.secondServiceField : ""
                        }`}
                        onKeyDown={onKeyDown}
                        fieldsData={fieldsData}
                    />
                );
            case "streetAddress":
                return (
                    <StreetAddressField
                        field={field}
                        fields={fields}
                        onChange={(field: Field, val: string) =>
                            handleFieldChange(field, val)
                        }
                        onBlur={(f: Field) =>
                            !isSecondServiceForm ? handleFieldBlur(f) : null
                        }
                        value={fieldState?.value}
                        type={type}
                        className={`${
                            classes?.fieldsClassName?.streetAddress ?? ""
                        } ${classes?.fieldsClassName?.all ?? ""} ${
                            isSecondServiceForm ? styles.secondServiceField : ""
                        }`}
                        currentStep={currentStep}
                        fieldsData={fieldsData}
                        colors={colors}
                        stepStatus={stepStatus}
                        noteIndent={`${
                            (isInputField ? classes?.noteIndent : "") as string
                        }`}
                    />
                );
            case "zipCodeExpandable":
                return (
                    <ZipCodeExpandable
                        field={field}
                        fields={fields ?? []}
                        onChange={(field: Field, val: string) =>
                            handleFieldChange(field, val)
                        }
                        value={fieldState?.value}
                        type={type}
                        className={`${classes?.fieldsClassName?.all ?? ""} ${
                            classes?.fieldsClassName?.zipcodeExpandable ?? ""
                        } ${
                            isSecondServiceForm ? styles.secondServiceField : ""
                        }`}
                        onKeyDown={onKeyDown}
                        fieldsData={fieldsData}
                        lightBgColor={colors.lightPrimaryColor}
                    />
                );

            case "date":
                return (
                    <DatePicker
                        field={field}
                        onChange={(val: string) =>
                            handleFieldChange(field, val)
                        }
                        onBlur={() => handleFieldBlur(field)}
                        value={fieldState?.value}
                        className={` ${classes?.fieldsClassName?.date ?? ""} ${
                            classes?.fieldsClassName?.all ?? ""
                        } ${
                            isSecondServiceForm ? styles.secondServiceField : ""
                        }`}
                    />
                );

            case "textarea":
                return (
                    <TextArea
                        field={field}
                        onChange={(val: string) =>
                            handleFieldChange(field, val)
                        }
                        onBlur={() => handleFieldBlur(field)}
                        value={fieldState?.value}
                        className={`${
                            classes?.fieldsClassName?.textarea ?? ""
                        } ${classes?.fieldsClassName?.all ?? ""} ${
                            isSecondServiceForm ? styles.secondServiceField : ""
                        }`}
                    />
                );

            case "select":
                return (
                    <Select
                        field={field}
                        onChange={(val: string) =>
                            handleFieldChange(field, val)
                        }
                        value={fieldState?.value}
                        className={`${classes?.fieldsClassName?.select ?? ""} ${
                            classes?.fieldsClassName?.all ?? ""
                        } ${
                            isSecondServiceForm ? styles.secondServiceField : ""
                        }`}
                    />
                );
            case "multiSelect":
            case "multipleSelect":
                return (
                    <MultipleSelect
                        onChange={(val: string) =>
                            handleFieldChange(field, val)
                        }
                        field={field}
                        value={fieldState?.value}
                        className={`${
                            isSecondServiceForm
                                ? classes?.fieldsClassName?.checkbox ?? ""
                                : classes?.fieldsClassName?.multiSelect ?? ""
                        } ${classes?.fieldsClassName?.all ?? ""} ${
                            isSecondServiceForm ? styles.secondServiceField : ""
                        }`}
                        isSecondServiceForm={isSecondServiceForm}
                        primaryColor={colors.primaryColor}
                        ignoreOptionIcons={ignoreOptionIcons}
                        searchable={searchableMultiSelect}
                        colorsConfig={{ tagColor: colors.multiSelectTagColor }}
                        classConfig={{
                            fieldErrorMessageClassName:
                                classes?.fieldErrorMessageClassName,
                            fieldNoteClassName: classes?.fieldNoteClassName,
                        }}
                        errorText={getFieldErrorMessage()}
                        noteText={decodeNote(field.note)}
                    />
                );
            case "calculatedYears":
                return (
                    <CalculatedYears
                        field={field}
                        onChange={(val: string) =>
                            handleFieldChange(field, val)
                        }
                        value={fieldState?.value}
                        className={`${classes?.fieldsClassName?.select ?? ""} ${
                            classes?.fieldsClassName?.all ?? ""
                        } ${
                            isSecondServiceForm ? styles.secondServiceField : ""
                        }`}
                    />
                );
            case "radio":
                return (
                    <Radio
                        onClick={(val: string, optionSelectedIndex: string) => {
                            handleFieldChange(field, val, optionSelectedIndex);
                        }}
                        onChange={(val: string) =>
                            handleFieldChange(field, val)
                        }
                        field={field}
                        value={fieldState?.value}
                        optionSelectedIndex={fieldState?.optionSelectedIndex}
                        className={`${classes?.fieldsClassName?.radio ?? ""} ${
                            classes?.fieldsClassName?.all ?? ""
                        } ${
                            isSecondServiceForm ? styles.secondServiceField : ""
                        }`}
                        primaryColor={colors.primaryColor}
                        ignoreOptionIcons={ignoreOptionIcons}
                        formId={formId}
                        isSecondServiceForm={isSecondServiceForm}
                    />
                );
            case "checkbox":
                return (
                    <Checkbox
                        onChange={(val: string) =>
                            handleFieldChange(field, val)
                        }
                        field={field}
                        value={fieldState?.value}
                        className={`${
                            classes?.fieldsClassName?.checkbox ?? ""
                        } ${classes?.fieldsClassName?.all ?? ""} ${
                            isSecondServiceForm ? styles.secondServiceField : ""
                        }`}
                        primaryColor={colors.primaryColor}
                        ignoreOptionIcons={ignoreOptionIcons}
                    />
                );
            case "range":
                return (
                    <Range
                        field={field}
                        onChange={(val: string) =>
                            handleFieldChange(field, val)
                        }
                        // onBlur={() => handleFieldBlur(field)}
                        value={fieldState?.value}
                        className={`${classes?.fieldsClassName?.range ?? ""} ${
                            classes?.fieldsClassName?.all ?? ""
                        } ${
                            isSecondServiceForm ? styles.secondServiceField : ""
                        }`}
                    />
                );
        }
    };
    useEffect(() => {
        if (
            !canShowFieldAfterDependency(field, fieldsData) &&
            fieldState.value !== ""
        )
            handleFieldChange(field, "");
    }, [fieldsData]);

    const fieldDescription = useMemo(() => {
        const fieldDesc = field.meta?.fieldDescription;
        return fieldDesc ?? null;
    }, [field]);
    const isInputField = useMemo(() => {
        const type = getFieldType();
        switch (type) {
            case "text":
            case "email":
            case "number":
            case "tel":
            case "streetAddress":
                return true;
            default:
                return false;
        }
    }, []);

    if (field.fieldType !== "hidden") {
        return (
            <div
                className={`${styles["form-field"]} ${
                    field.conditionalLogic && field.conditionalLogic.isActive
                        ? styles["conditionalLogic"]
                        : ""
                } ${!fieldState?.valid ? styles["error"] : ""} ${
                    classes?.formFieldClassName ?? ""
                }`}
                ref={(node) => {
                    if (node) {
                        if (!canShowFieldAfterDependency(field, fieldsData)) {
                            node.style.setProperty(
                                "display",
                                "none",
                                "important",
                            );
                        } else {
                            node.style.setProperty("display", null);
                        }
                    }
                }}
            >
                {field.label && !isExpandable && (
                    <label
                        className={`${styles["field-label"]} ${
                            classes?.formFieldLabelClassName ?? ""
                        } ${
                            asTitle
                                ? `${styles["as-title"]} ${
                                      classes?.labelAsTitleClassName ?? ""
                                  }`
                                : `${
                                      isInputField
                                          ? (classes?.roundedLabelShift as string)
                                              ? (classes?.roundedLabelShift as string)
                                              : ""
                                          : ""
                                  }`
                        }`}
                    >
                        <span>{getFieldLabel()}</span>

                        {field.meta?.tooltip && !isExpandable && (
                            <Tooltip
                                placement="bottom"
                                overlay={<span>{field.meta.tooltip}</span>}
                                trigger={["hover"]}
                                overlayClassName={styles["tooltip"]}
                            >
                                <div className={styles["tooltip-icon"]}>
                                    <TooltipIcon />
                                </div>
                            </Tooltip>
                        )}
                        {fieldDescription && (
                            <div
                                className={`${styles["field-description"]} ${
                                    classes?.formFieldDescriptionClassName ?? ""
                                }`}
                            >
                                <span>{fieldDescription}</span>
                            </div>
                        )}
                    </label>
                )}

                {gettingField()}
                {!query?.countryCode &&
                selectedCountryCode &&
                field.codeName === "zipCode" &&
                form?.supportedCountries?.find(
                    (item) => item.value === selectedCountryCode,
                ) ? (
                    <div
                        className="text-xs mt-1 country-code-disclaimer"
                        style={{
                            color:
                                domain.config?.countryCodeColors?.textColor ??
                                "black",
                        }}
                    >
                        Your current location is set to{" "}
                        {selectedCountryCode === "US"
                            ? "United States"
                            : "Canada"}
                        ,{" "}
                        <span
                            className="text-[#15B4F9] hover:underline cursor-pointer "
                            style={{
                                color:
                                    domain.config?.countryCodeColors
                                        ?.clickHereColor ?? "#15B4F9",
                            }}
                            onClick={() => {
                                if (selectedCountryCode === "CA") {
                                    updateSelectedCountryCode("US");
                                    return;
                                }

                                updateSelectedCountryCode("CA");
                            }}
                        >
                            Click here
                        </span>{" "}
                        to change your location to{" "}
                        {selectedCountryCode === "US"
                            ? "Canada"
                            : "United States"}
                        .
                    </div>
                ) : (
                    <></>
                )}

                {field.fieldType !== "streetAddress" &&
                    field.fieldType !== "multiSelect" &&
                    field.fieldType !== "multipleSelect" && (
                        <>
                            <div
                                className={`${styles["error-message"]} ${
                                    classes?.fieldErrorMessageClassName ?? ""
                                } ${
                                    field.codeName === "date"
                                        ? "w-[90%] md:w-[54%]  mx-auto"
                                        : ""
                                }`}
                            >
                                <span>{getFieldErrorMessage()}</span>
                            </div>
                            {field.note && (
                                <div
                                    className={`${styles["field-note"]} ${
                                        classes?.fieldNoteClassName ?? ""
                                    } ${
                                        (isInputField
                                            ? classes?.noteIndent
                                            : "") as string
                                    }`}
                                >
                                    <span>{decodeNote(field.note)}</span>
                                </div>
                            )}
                        </>
                    )}
            </div>
        );
    }
    return <></>;
};
